<template>
  <div class="question-result">
    <div class="questionnaire-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">AI生涯规划推荐</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="result-body">
      <el-container>
        <el-header>
          <el-row :gutter="10">
            <el-col :span="3"><div class="grid-content"></div></el-col>
            <el-col :span="21"><div class="grid-content short-color">短期计划（在校期间）</div></el-col>
          </el-row>
        </el-header>
        <el-main>
          <el-row :gutter="10" style="height: 21%;">
            <el-col :span="3"><div class="grid-main-left">本期目标</div></el-col>
            <el-col :span="21"><div class="grid-main">
              <el-image class="small" v-if="resultData[0].isLoading" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
              <span v-else>{{ resultData[0].data }}</span>
            </div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 29%;">
            <el-col :span="3"><div class="grid-main-left">细分目标</div></el-col>
            <el-col :span="21"><div class="grid-main">
              <el-image class="small" v-if="resultData[1].isLoading" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
              <span v-else>{{ resultData[1].data }}</span>
            </div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 21%;">
            <el-col :span="3"><div class="grid-main-left">计划内容</div></el-col>
            <el-col :span="21"><div class="grid-main">
              <el-image class="small" v-if="resultData[2].isLoading" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
              <span v-else>{{ resultData[2].data }}</span>
            </div></el-col>
          </el-row>
          <el-row :gutter="10" style="height: 29%;">
            <el-col :span="3"><div class="grid-main-left">策略和措施</div></el-col>
            <el-col :span="21"><div class="grid-main">
              <el-image class="small" v-if="resultData[3].isLoading" :src="require(`../../../assets/image/loading.gif`)" fit="fit"></el-image>
              <span v-else>{{ resultData[3].data }}</span>
            </div></el-col>
          </el-row>
        </el-main>
      </el-container>
      <div class="body-bottom">
        <el-button class="restart-button" @click="restartButton">重做</el-button>
        <el-button class="next-button" @click="nextButton">下一页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getResult } from "../../../request/api";

export default {
  data () {
    return {
      questionnaire_result_id: undefined,
      resultData: [{ isLoading: true, data: '' }, { isLoading: true, data: '' }, { isLoading: true, data: '' }, { isLoading: true, data: '' }]
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    restartButton () {
      if (this.resultData[0].isLoading || this.resultData[1].isLoading || this.resultData[2].isLoading || this.resultData[3].isLoading) return

      this.resultData = [{ isLoading: true, data: '' }, { isLoading: true, data: '' }, { isLoading: true, data: '' }, { isLoading: true, data: '' }]
      this.init()
    },
    nextButton () {
      if (
        this.resultData[0].isLoading === false &&
        this.resultData[1].isLoading === false &&
        this.resultData[2].isLoading === false &&
        this.resultData[3].isLoading === false
      ) {
        this.$router.push(
          {
            path: '/questionResultTarget',
            query: {
              questionnaire_result_id: this.questionnaire_result_id
            }
          }
        )
      }
    },
    async init() {
      if (this.questionnaire_result_id === undefined) return

      try {
        for (let i = 1; i <= this.resultData.length; i++) {
          this.getResultData(this.questionnaire_result_id, i)
        }
      } catch (error) {
        this.$message.error('出错了', error)
      }
    },
    async getResultData(questionnaire_result_id, id) {
      try {
        const res = await getResult(questionnaire_result_id, id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        if (id === 1) { this.resultData[0].isLoading = false; this.resultData[0].data = res.data.data; }
        else if (id === 2) { this.resultData[1].isLoading = false; this.resultData[1].data = res.data.data; }
        else if (id === 3) { this.resultData[2].isLoading = false; this.resultData[2].data = res.data.data; }
        else if (id === 4) { this.resultData[3].isLoading = false; this.resultData[3].data = res.data.data; }
      } catch(err) {
        this.getResultData(questionnaire_result_id, id)
      }
    }
  },
  created () {
    this.questionnaire_result_id = this.$route.query.questionnaire_result_id
  },
  mounted () {
    console.log('在这里判断是否存在历史记录')
    this.init()
  }
}
</script>

<style lang="less" scoped>
.question-result {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .questionnaire-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .result-body {
    background-color: #fff;
    height: 92%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .body-bottom {
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .restart-button {
        background-color: #409eff;
        color: #fff;
        width: 90px;
        border: none;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #66b1ff;
        }
      }
      .next-button {
        background-color: #ffcf34;
        color: #000;
        width: 90px;
        border: none;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #ffe284;
        }
      }
    }
    .el-container {
      height: 90%;
      .el-header {
        .grid-content {
          border-radius: 4px;
          height: 52px;
          color: #fff;
          font-weight: bold;
          line-height: 52px;
          text-align: center;
          font-size: 22px;
        }
        .short-color {
          background-color: #9fd5ff;
        }
        .medium-color {
          background-color: #5eb8ff;
        }
        .long-color {
          background-color: #1096ff;
        }
      }
      .el-main {
        padding: 0 20px;
        .el-col {
          height: 100%;
          .grid-main-left {
            background-color: #ffe284;
            width: 120px;
            height: 40px;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            margin: auto;
          }
          .grid-main {
            border: 1px solid #9fd5ff;
            height: 90%;
            border-radius: 4px;
            padding: 4px;
            box-sizing: border-box;
            overflow-y: auto;
            font-size: 12px;
            display: flex;
            justify-content: center;
            white-space: pre-wrap;
            .small {
              height: 40px;
            }
          }
        }
      }
    }
  }
}
</style>